@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nanum+Brush+Script&family=Raleway:wght@300&display=swap');

body {
  font-family: 'Raleway', sans-serif;
}

h1,
h2,
h3 {
  font-family: 'Nanum Brush Script', cursive;
}

.about-page {
  clip-path: polygon(
    10% 0,
    90% 0,
    100% 10%,
    100% 90%,
    90% 100%,
    10% 100%,
    0 90%,
    0 10%
  );
}

.about-home,
.contact-frame,
.product-image {
  clip-path: polygon(
    5% 0,
    95% 0,
    100% 5%,
    100% 95%,
    95% 100%,
    5% 100%,
    0 95%,
    0 5%
  );
}

.other-images,
.hero-slide,
.about-home,
.location {
  clip-path: polygon(
    10% 0,
    90% 0,
    100% 10%,
    100% 90%,
    90% 100%,
    10% 100%,
    0 90%,
    0 10%
  );
}

@media (max-width: 767px) {
  .contact-image {
    height: 30vh;
  }
}
